import React from "react";
import Navbar from "../Components/Nabar/Navbar";
import PowerBI from "../Components/PowerBI/PowerBI";

const Airlines = () => {
	return (
		<>
			<Navbar />
			<section className="sections airlines-section">
				<div className="container">
					<h1 className="section-heading">Airlines Report</h1>
					<hr />
				</div>
				<PowerBI PowerBIURL={process.env.REACT_APP_AIRLINE} />
			</section>
		</>
	);
};

export default Airlines;
