import React, { useState } from "react";
import Navbar from "../Components/Nabar/Navbar";
import axios from "axios";
import Loader from "react-loader-spinner";
import { Email, LocationOn } from "@material-ui/icons";

const Contact = () => {
	const [formData, setFormData] = useState({
		name: "",
		email: "",
		details: "",
	});
	const [loading, setLoading] = useState(false);
	const [status, setStatus] = useState("");
	const [warning, setWarning] = useState("");
	const { name, email, details } = formData;
	const InputEvent = (e) => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value,
		});
	};
	const onSubmit = (e) => {
		e.preventDefault();
		setLoading(true);

		let URL = `https://prod-131.westus.logic.azure.com/workflows/9943f1c52924429ca52ccd12dfa7e8d3/triggers/manual/paths/invoke/${formData.email}/${formData.name}/${formData.details}?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=Zbc0MYaZ-bO0w3V_enlmKmYiig0pPqC2_cDxMy7alzY`;

		axios
			.get(URL)
			.then((res) => {
				setStatus("Message Sent Successfully");
				setFormData({
					name: "",
					email: "",
					details: "",
				});
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
				setWarning("Message counldn't be sent");
			});

		setTimeout(() => {
			setStatus("");
			setWarning("");
		}, 5000);
	};
	return (
		<>
			<Navbar />
			<section className="sections contact-section">
				<div className="container">
					<h1 className="section-heading">Contact Us</h1>
					<hr />
				</div>
				<div className="row mt-5 mx-2">
					<div className="col-md-6 col-12 mx-auto mt-3">
						<form className="contact-form" onSubmit={onSubmit}>
							<div className="form-group">
								<label className="labelStyle">Name</label>
								<input required type="text" name="name" placeholder="Enter Your Name" value={name} onChange={(e) => InputEvent(e)} className="inputField form-control py-2" />
							</div>
							<div className="form-group my-3">
								<label className="labelStyle">Email</label>
								<input required type="email" value={email} placeholder="Enter Your Email" onChange={(e) => InputEvent(e)} name="email" className="inputField form-control py-2" />
							</div>
							<div className="form-group my-3">
								<label className="labelStyle">Project Description</label>
								<textarea required name="details" value={details} placeholder="Enter Project Description" onChange={(e) => InputEvent(e)} className="textarea form-control py-2" rows="5" />
							</div>
							<div className="form-group my-3">
								{loading ? (
									<div className="loader-div">
										<Loader />
									</div>
								) : (
									<button className="formButton" type="submit">
										Send Message
									</button>
								)}
							</div>
							<div className="form-group">
								{status ? <h5 className="text-success">{status}</h5> : null}
								{warning ? <h5 className="text-danger">{warning}</h5> : null}
							</div>
						</form>
					</div>
					<div className="col-md-6 col-12 mx-auto pt-2 map">
						<div className="contact-info">
							<div className="row py-2">
								<div className="col-2 d-flex align-items-center">
									<LocationOn color="primary" fontSize="large" />
								</div>
								<div className="col-10">
									<h6>Location: </h6>
									<p className="m-0">Indore, Madhya Pradesh (HQ)</p>
									<p className="m-0">Hyderabad, Telangana</p>
								</div>
							</div>
							<div className="row pt-2 pb-4">
								<div className="col-2 d-flex align-items-center">
									<Email color="primary" fontSize="large" />
								</div>
								<div className="col-10">
									<h6>Email: </h6>
									<p className="m-0">
										<a href="mailto: rahulj@techdome.io" className="text-black">
											rahulj@techdome.io
										</a>
									</p>
									<p className="m-0">
										<a href="mailto: pratikm@techdome.io" className="text-black">
											pratikm@techdome.io
										</a>
									</p>
								</div>
							</div>
						</div>
						<div className="px-2">
							<iframe
								width="100%"
								height="350"
								frameborder="0"
								scrolling="no"
								marginheight="0"
								title="Map"
								marginwidth="0"
								src="https://maps.google.com/maps?width=100%25&amp;height=400&amp;hl=en&amp;q=Vidhya%20Palace,%20Indore,%20Madhya%20Pradesh+(Techdome%20Solutions%20Private%20Limited)&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
							></iframe>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Contact;
