import "./App.css";
import Layout from "./hocs/Layout";

function App() {
    return (
        <>
            <Layout />
        </>
    );
}

export default App;
