import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import Logo from "../Assets/logo.png";

const Login = () => {
    const [user, setUser] = useState({
        username: "user",
        password: "",
    });
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [error, setError] = useState(false);
    const onSubmit = (e) => {
        e.preventDefault();
        if (user.username === "user" && user.password === "pass") {
            setIsAuthenticated(true);
        } else {
            setError(true);
        }
    };
    if (isAuthenticated) {
        return <Redirect to="/salesreport1" />;
    }
    const onConfirm = () => {
        setError(false);
    };
    return (
        <>
            {error ? (
                <SweetAlert
                    title="Login Failed"
                    onConfirm={onConfirm}
                    type="danger"
                    focusConfirmBtn="true"
                ></SweetAlert>
            ) : null}
            <div className="container py-5">
                <div className="d-flex justify-content-center">
                    <figure>
                        <img
                            src={Logo}
                            alt="Techdome Logo"
                            height="180"
                            width="180"
                        />
                    </figure>
                </div>
                <h1 className="loginHeading">Login</h1>
                <div className="row">
                    <div className="col-12">
                        <form onSubmit={onSubmit}>
                            <div className="form-group">
                                <input
                                    type="text"
                                    className="form-control my-4 py-3"
                                    value={user.username}
                                    name="username"
                                    placeholder="Username"
                                    required
                                    id="username"
                                    autocomplete="off"
                                    onChange={(e) => {
                                        setUser({
                                            ...user,
                                            username: e.target.value,
                                        });
                                    }}
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="password"
                                    className="form-control my-4 py-3"
                                    value={user.password}
                                    name="password"
                                    placeholder="Password"
                                    required
                                    id="password"
                                    autoFocus
                                    autocomplete="off"
                                    onChange={(e) => {
                                        setUser({
                                            ...user,
                                            password: e.target.value,
                                        });
                                    }}
                                />
                            </div>
                            <div className="form-group">
                                <button
                                    className="btn btn-primary px-4 py-2"
                                    type="submit"
                                >
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Login;
