import React from "react";
import Navbar from "../Components/Nabar/Navbar";
import PowerBI from "../Components/PowerBI/PowerBI";

const ReportAnalysis2 = () => {
	return (
		<>
			<Navbar />
			<section className="sections sales-section">
				<div className="container">
					<h1 className="section-heading">Report Analysis 2</h1>
					<hr />
				</div>
				<PowerBI PowerBIURL={process.env.REACT_APP_REPORT_ANALYSIS_2} />
			</section>
		</>
	);
};

export default ReportAnalysis2;
