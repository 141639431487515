import React from "react";
import { Switch, Route } from "react-router-dom";
import Airlines from "../Containers/Airlines";
import Contact from "../Containers/Contact";
import Error from "../Containers/Error";
import FintechReport from "../Containers/FintechReport";
import SalesReport2 from "../Containers/SalesReport2";
import Login from "../Containers/Login";
import SalesReport1 from "../Containers/SalesReport1";
import ReportAnalysis1 from "../Containers/ReportAnalysis1";
import ReportAnalysis2 from "../Containers/ReportAnalysis2";

const Routes = () => {
	return (
		<>
			<Switch>
				<Route exact path="/" component={Login} />
				<Route exact path="/salesreport1" component={SalesReport1} />
				<Route exact path="/salesreport2" component={SalesReport2} />
				<Route exact path="/reportanalysis1" component={ReportAnalysis1} />
				<Route exact path="/reportanalysis2" component={ReportAnalysis2} />
				<Route exact path="/fintech" component={FintechReport} />
				<Route exact path="/airlines" component={Airlines} />

				<Route exact path="/contact" component={Contact} />
				<Route component={Error} />
			</Switch>
		</>
	);
};

export default Routes;
