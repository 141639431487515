import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
	return (
		<>
			<h6 className="text-center text-light mt-3 mb-4">
				<Link exact to="/contact">
					Contact Us
				</Link>
			</h6>
			<h6 className="text-center text-light">
				Copyright &copy; 2021{" "}
				<a href="https://techdome.io" target="_blank" rel="noreferrer">
					Techdome Solutions Pvt. Ltd.
				</a>
			</h6>
		</>
	);
};

export default Footer;
