import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Logo from "../../Assets/logo.png";
import Footer from "../Footer/Footer";

const Navbar = () => {
	const [toggle, setToggle] = useState(false);
	return (
		<>
			<button className="toggle" onClick={(e) => setToggle(!toggle)}>
				<div className="line" />
				<div className="line" />
				<div className="line" />
			</button>

			<div className={`qf-navbar ${toggle ? "shownav" : "hidenav"}`}>
				<div className="d-flex justify-content-center mt-3">
					<figure>
						<img src={Logo} alt="Techdome Logo" width="100" height="100" />
					</figure>
				</div>

				<h1>Techdome</h1>

				<div className="nav-items">
					<li>
						<NavLink className="nav-item" exact to="/salesreport1" activeClassName="active">
							Sales Report 1
						</NavLink>
					</li>
					<li>
						<NavLink className="nav-item" exact to="/salesreport2" activeClassName="active">
							Sales Report 2
						</NavLink>
					</li>
					<li>
						<NavLink className="nav-item" exact to="/reportanalysis1" activeClassName="active">
							Report Analysis 1
						</NavLink>
					</li>
					<li>
						<NavLink className="nav-item" exact to="/reportanalysis2" activeClassName="active">
							Report Analysis 2
						</NavLink>
					</li>
					<li>
						<NavLink className="nav-item" exact to="/fintech" activeClassName="active">
							Fintech Report
						</NavLink>
					</li>
					<li>
						<NavLink className="nav-item" exact to="/airlines" activeClassName="active">
							Airlines Report
						</NavLink>
					</li>
				</div>
				<div className="logout-section w-100 d-flex justify-content-center mt-4">
					<NavLink className="btn btn-danger text-light" to="/">
						Logout
					</NavLink>
				</div>
				<Footer />
			</div>
		</>
	);
};

export default Navbar;
